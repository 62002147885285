import React from "react";

const SubIcon = ({
  size = 24,
  color = "#000000",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="4999"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M192 480m32 0l608 0q32 0 32 32l0 0q0 32-32 32l-608 0q-32 0-32-32l0 0q0-32 32-32Z"
      fill={color}
      p-id="5000"
    ></path>
  </svg>
);

export default SubIcon;
