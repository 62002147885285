import React from "react";

const Medal = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="51864"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M956.5 825.2c-1.9 10.4-11.4 14.3-28.2 11.7-16.9-3.2-44.5-8.4-82.7-15.6l-63.3-11.7c-1.3 5.2-8 27.7-20 67.6-12 39.9-20.9 64.7-26.8 74.5-14.3 10.4-25.3 11-33.1 1.9-7.8-9.1-11.7-14.6-11.7-16.5L562.3 685c-16.2 2.6-32.8 3.9-49.6 3.9-12.3 0-24.3-1-36-2.9L334.5 955.6c-0.7 0.6-3.6 1.8-8.8 3.4-5.2 1.6-12.3 0.5-21.4-3.4-8.4-9.1-20-34.6-34.6-76.4-14.6-41.9-22.9-65.7-24.8-71.5-5.2 1.3-29.5 6.5-73 15.6s-73.7 13.6-90.5 13.6c-11-1.9-16.4-7.1-16.1-15.6 0.3-8.4 0.8-13 1.5-13.6L226.5 502c-16.9-38.9-25.6-80.8-26.3-125.6 2.6-88.2 33.2-161.7 92-220.5 58.7-58.7 132.2-89.4 220.5-92 88.2 2.6 161.7 33.3 220.5 92 58.7 58.7 89.4 132.2 92 220.5-0.7 50.6-11.7 97-33.1 139.2l166.4 292c0.6 1.4-0.1 7.2-2 17.6zM404.6 669.4c-50.6-18.8-93.4-48.7-128.5-89.5l-90.5 168.4 104.1-30.2 40.9 99.3 74-148z m279.3-121.6c45.4-45.4 69.1-102.5 71.1-171.3-1.9-68.8-25.6-126-71.1-171.8-45.4-45.7-102.5-69.3-171.3-70.6-68.8 1.3-126 24.8-171.8 70.6s-69.3 103-70.6 171.8c1.3 68.8 24.8 125.9 70.6 171.3 45.7 45.4 103 69.1 171.8 71.1 68.8-2 125.9-25.7 171.3-71.1z m-73-139.2l14.6 116.8-105.1-50.6-106.1 50.6 14.6-116.8-79.8-85.7L464 300.5l56.5-102.2L576 300.5l114.9 22.4-80 85.7z m-49.6-19.5l37-38.9-52.6-9.7-25.3-46.7-26.3 46.7-51.6 9.7 36 38.9-6.8 53.5 48.7-23.4 47.7 23.4-6.8-53.5z m273.5 359.2l-93.4-159.6c-31.8 33.7-69.4 59.7-112.9 77.9l81.8 156.7 37-90.5 87.5 15.5z"
      fill={color}
      p-id="51865"
    ></path>
  </svg>
);

export default Medal;
