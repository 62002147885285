import React, { Component } from "react";
import "../style/refundPolicy.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";

class RefundPolicy extends Component {
  constructor(props) {
    super(props);
    this.routeContact = this.routeContact.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routeContact() {
    const { history } = this.props;
    history.push("/Contact");
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="refund-page">
          <h1>Refund policy</h1>
          <p>
            We have a 90-day return policy, which means you have 90 days after
            receiving your item to request a return.
          </p>
          <p>
            To be eligible for a return, your item must be in the same condition
            that you received it, unused, and in its original packaging. You’ll
            also need proof of purchase.
          </p>
          <p>
            To start a return, you can contact us via{" "}
            <span onClick={this.routeContact}>this form</span>. If your return
            is accepted, we’ll send you a return shipping label, as well as
            instructions on how and where to send your package. Items sent back
            to us without first requesting a return will not be accepted.
          </p>
          <p>
            You can always contact us for any return question via{" "}
            <span onClick={this.routeContact}>this form</span>.
          </p>
          <p>
            <b> Damages and issues</b> <br /> Please inspect your order upon
            reception and contact us immediately if the item is defective,
            damaged or if you receive the wrong item, so that we can evaluate
            the issue and make it right.
          </p>
          <p>
            <b>Refunds</b> <br /> We will notify you once we’ve received and
            inspected your return, and let you know if the refund was approved
            or not. If approved, you’ll be automatically refunded on your
            original payment method. Please remember it can take some time for
            your bank or credit card company to process and post the refund too.
          </p>
          <p>
            {/* TODO: 公司地址 */}
            Return Address: <br />
            3A Back Lord St , <br />
            Halifax , <br />
            HX1 5AG , <br />
            United Kingdom.
          </p>
          <p></p>
          {/* TODO: 公司电话 */}
          <p>Telephone: 020 7706 7032</p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default RefundPolicy;
