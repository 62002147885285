import React from "react";
const AddIcon = ({
    viewBox = '0 0 24 24',
    size = 24,
    color = "#31303D",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="2331"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M640 469.333333h170.666667a42.666667 42.666667 0 0 1 0 85.333334h-170.666667a42.666667 42.666667 0 0 1 0-85.333334z m-85.333333 341.333334a42.666667 42.666667 0 0 1-85.333334 0v-256H213.333333a42.666667 42.666667 0 0 1 0-85.333334h256V213.333333a42.666667 42.666667 0 0 1 85.333334 0v597.333334z"
      fill={color}
      p-id="2332"
    ></path>
  </svg>
);

export default AddIcon;