import React from "react";

const Return = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="57702"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M929.29 504.29a306.65 306.65 0 0 0-67.23-97.36 315 315 0 0 0-220.61-89.76H171.26l216.37-177.78-31.74-38.63-293.43 241.1L355.89 583l31.74-38.64-216.28-177.74h470.1a264.48 264.48 0 0 1 186 75.67c49.74 48.64 77.13 113 77.13 181.19S877.22 756 827.48 804.67a264.52 264.52 0 0 1-186 75.67H162.09v49.45h479.36A315 315 0 0 0 862.06 840a306.43 306.43 0 0 0 67.23-97.36 299.12 299.12 0 0 0 0-238.37z"
      fill={color}
      p-id="57703"
    ></path>
  </svg>
);

export default Return;
