import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import Number from "./common/Number";
import "../style/cart.scss";
import RemoveIcon from "../icon/RemoveIcon";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartArr: [],
      cartNum: 0,
      cartTotal: 0,
    };
    this.callBackNum = this.callBackNum.bind(this);
    this.routeHome = this.routeHome.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.routeCheck = this.routeCheck.bind(this);
  }

  numberRef = React.createRef();
  headerRef = React.createRef();

  componentDidMount() {
    let cartTotal = 0;
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      let cartNum = JSON.parse(localStorage.getItem("cartNum"));
      cartArr.map((item) => {
        item.total = (item.num * item.price).toFixed(2);
        cartTotal += parseFloat(item.total);
      });
      this.setState({ cartArr, cartNum, cartTotal: cartTotal.toFixed(2) });
    }
  }

  callBackNum(num, productIndex) {
    let { cartArr } = this.state;
    let cartNum = 0;
    let cartTotal = 0;
    cartArr.map((item, index) => {
      if (index === productIndex) {
        item.num = num;
        item.total = (num * item.price).toFixed(2);
      }
      cartNum += item.num;
      cartTotal += parseFloat(item.total);
    });
    this.setState({ cartArr, cartNum, cartTotal: cartTotal.toFixed(2) }, () => {
      if (this.headerRef) {
        this.headerRef.current.setState({
          cartArr,
          cartNum,
        });
      }
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      localStorage.setItem("cartNum", JSON.stringify(cartNum));
    });
  }

  removeProduct(index) {
    let { cartArr, cartNum, cartTotal } = this.state;
    cartTotal = cartTotal - cartArr[index].total;
    cartNum = cartNum - cartArr[index].num;
    cartArr.splice(index, 1);
    this.setState({ cartArr, cartTotal: cartTotal.toFixed(2), cartNum }, () => {
      if (this.headerRef) {
        this.headerRef.current.setState({
          cartArr,
          cartNum,
        });
      }
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      localStorage.setItem("cartNum", JSON.stringify(cartNum));
    });
  }

  routeHome() {
    const { history } = this.props;
    history.push("/");
  }
  routeCheck(){
    const { history } = this.props;
    history.push("/Checkout");
  }

  render() {
    const { cartArr, cartTotal } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="cart-page">
          {cartArr.length > 0 ? (
            <React.Fragment>
              <h1>Shopping Cart</h1>
              <div className="product-body">
                <div className="left">
                  <div className="th">
                    <div className="product">RODUCT</div>
                    <div className="price">PRICE</div>
                    <div className="quantity">QUANTITY</div>
                    <div className="total">TOTAL</div>
                  </div>
                  {cartArr.map((item, index) => {
                    return (
                      <div className="tb" key={index}>
                        <div className="product">
                          <RemoveIcon
                            size={16}
                            onClick={() => this.removeProduct(index)}
                          />{" "}
                          <img src={item.img[0]} alt="" />
                          <p>{item.txt}</p>
                        </div>
                        <div className="price">{item.price}</div>
                        <div className="quantity">
                          <Number
                            {...this.props}
                            num={item.num}
                            ref={this.numberRef}
                            receiveNum={(num) => this.callBackNum(num, index)}
                          />
                        </div>
                        <div className="total">{item.total}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="right">
                  <div className="title">CART TOTAL</div>
                  <div className="subtotal">
                    SUBTOTAL <span>￡{cartTotal}</span>
                  </div>
                  <p>
                    Postage is only £1 or FREE if you spend over £5. Express
                    delivery options are also available at checkout.
                  </p>
                  <div className="btn" onClick={this.routeCheck}>CHECKOUT</div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <div className="no-product">
              <h1>Shopping Cart is empty</h1>
              <p>Your shopping cart is empty.</p>
              <div className="btn" onClick={this.routeHome}>
                CONTINUE SHOPPING
              </div>
            </div>
          )}
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Cart;
