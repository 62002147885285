import React from "react";
const Ship = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "rgb(65, 71, 85)",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="11262"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M972.8 354.133333l-179.2-149.333333c-25.6-21.333333-55.466667-34.133333-89.6-34.133333h-426.666667c-38.4 0-72.533333 17.066667-93.866666 42.666666h136.533333c12.8 0 21.333333 8.533333 21.333333 21.333334s-8.533333 21.333333-21.333333 21.333333h-298.666667c-12.8 0-21.333333 8.533333-21.333333 21.333333s8.533333 21.333333 21.333333 21.333334h115.2l-21.333333 42.666666h119.466667c12.8 0 21.333333 8.533333 21.333333 21.333334s-8.533333 21.333333-21.333333 21.333333h-213.333334c-12.8 0-21.333333 8.533333-21.333333 21.333333s8.533333 21.333333 21.333333 21.333334h51.2l-21.333333 42.666666h98.133333c12.8 0 21.333333 8.533333 21.333334 21.333334s-8.533333 21.333333-21.333334 21.333333h-128c-12.8 0-21.333333 8.533333-21.333333 21.333333 0 8.533333 4.266667 12.8 12.8 17.066667-8.533333 17.066667-12.8 34.133333-12.8 51.2 0 42.666667 21.333333 81.066667 55.466667 102.4 4.266667 4.266667 12.8 4.266667 21.333333 0 4.266667-4.266667 8.533333-8.533333 8.533333-17.066667 8.533333-98.133333 93.866667-174.933333 192-174.933333C384 512 469.333333 597.333333 469.333333 704c0 12.8 8.533333 21.333333 21.333334 21.333333h42.666666c12.8 0 21.333333-8.533333 21.333334-21.333333 0-106.666667 85.333333-192 192-192 85.333333 0 157.866667 55.466667 183.466666 136.533333 4.266667 8.533333 8.533333 12.8 17.066667 12.8s17.066667-4.266667 21.333333-12.8l21.333334-42.666666c21.333333-42.666667 34.133333-93.866667 34.133333-145.066667 0-42.666667-17.066667-81.066667-51.2-106.666667z m-34.133333 102.4c-4.266667 8.533333-12.8 12.8-21.333334 12.8h-256c-8.533333 0-12.8-4.266667-17.066666-8.533333-4.266667-4.266667-4.266667-12.8-4.266667-17.066667l42.666667-170.666666c0-8.533333 8.533333-12.8 12.8-17.066667 8.533333-4.266667 12.8 0 21.333333 4.266667l213.333333 170.666666c8.533333 4.266667 8.533333 17.066667 8.533334 25.6z"
      fill={color}
      p-id="11263"
    ></path>
    <path
      d="M277.333333 554.666667C196.266667 554.666667 128 622.933333 128 704S196.266667 853.333333 277.333333 853.333333s149.333333-68.266667 149.333334-149.333333S358.4 554.666667 277.333333 554.666667zM746.666667 554.666667c-81.066667 0-149.333333 68.266667-149.333334 149.333333s68.266667 149.333333 149.333334 149.333333 149.333333-68.266667 149.333333-149.333333-68.266667-149.333333-149.333333-149.333333z"
      fill={color}
      p-id="11264"
    ></path>
  </svg>
);

export default Ship;
