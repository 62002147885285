import PhoneNumber, { parsePhoneNumber } from "awesome-phonenumber";

export const isValidPhoneNumber = (value) => {
  if (!value) return false;
  const phoneNumber = new PhoneNumber(value);

  if (parsePhoneNumber(value).regionCode === "US") {
    // 美国电话号码只校验10位数字即可
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber.getNumber("significant"));
  }
  if (phoneNumber.isValid(value)) {
    return true;
  }
  return phoneNumber.isValid(value);
};

export const isValidEmail = (value) => {
  if (!value) return false;
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  if (reg.test(value)) {
    return true;
  }
};

export const isValidPassword = (value) =>{
  if(!value) return false;
  const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/;
  if(reg.test(value)){
    return true;
  }
}
