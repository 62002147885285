import React, { Component } from "react";
import "../style/aboutUs.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import img1 from "../image/woman.webp";
import img2 from "../image/herb.webp";
import img3 from "../image/flower.webp";

class AboutUs extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="about-page">
          <h1>About Us</h1>
          <p>
            Core Data One Seeds began, selling our
            <span> signature wildflower seed mix</span>. Our aim was to restore
            wildlife habitats and repopulate native flower species by making it
            easy for everyone to grow wildflowers, no matter what space they
            have. To make growing wildflowers even more accessible, we soon
            introduced our <span>Pet-Friendly Wildflower Seeds.</span> Safe for
            dogs, cats, rabbits, horses, and cattle, they were a popular
            addition to our range.
          </p>
          <div className="img">
            <img src={img1} alt="" />
          </div>
          <p>
            Over time we really got to see how invested people are in bringing
            back native British wildflowers. So, we expanded our range further,
            offering a huge range of individual wildflower seed packets. Now you
            can introduce <span>Forget-Me-Nots</span> and unique plants like{" "}
            <span> Water Avens</span> to your garden.
          </p>
          <div className="img">
            <img src={img2} alt="" />
          </div>
          <p>
            Since then we have expanded into <span>vegetable seeds</span> and
            <span>herb seeds</span>, so your garden can now feed you and the
            bees! Just like all our <span>wildflower seeds</span>, we've
            carefully curated a range of <span>easy-to-grow vegetables</span>{" "}
            and <span>herbs</span> that are sure to provide you with the best
            harvests. From <span>juicy carrots</span> to{" "}
            <span>plump tomatoes</span> and <span>fragrant basil</span> to
            <span>savoury thyme</span>, our specially selected varieties have
            something for everyone.
          </p>
          <p>
            No matter how much space you have, there's always room for nature in
            your garden. <span>Browse our shop</span> to see our{" "}
            <span>full range of seeds</span> and find your next gardening
            project today.
          </p>
          <div className="img">
            <img src={img3} alt="" />
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default AboutUs;
