import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ShippingPolicy from "./components/ShippingPolicy";
import RefundPolicy from "./components/RefundPolicy";
import Service from "./components/Service";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import ProductDetail from "./components/ProductDetail";
import Cart from "./components/Cart";
import Checkout from "./components/Checkout";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route
              path="/ProductDetail/:id"
              render={(props) => (
                <ProductDetail key={props.match.params.id} {...props} />
              )}
            />
            <Route path="/Checkout" component={Checkout}></Route>
            <Route path="/Cart" component={Cart}></Route>
            <Route path="/Contact" component={ContactUs}></Route>
            <Route path="/Privacy" component={PrivacyPolicy}></Route>
            <Route path="/Service" component={Service}></Route>
            <Route path="/Refund" component={RefundPolicy}></Route>
            <Route path="/Ship" component={ShippingPolicy}></Route>
            <Route path="/About" component={AboutUs}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
export default App;
