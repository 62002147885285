import React, { Component } from "react";
import "../style/home.scss";
import banner from "../image/banner.webp";
import img1 from "../image/img1.avif";
import row1 from "../image/row1.jpg";
import row2 from "../image/row2.jpg";
import row3 from "../image/row3.jpg";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import Package from "../icon/Package";
import Percent from "../icon/Percent";
import Medal from "../icon/Medal";
import Return from "../icon/Return";
import FooterBar from "./common/FooterBar";
import HeaderBar from "./common/HeaderBar";
import { Select } from "antd";
import { productList } from "../apis/GetProduct";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      cartArr: [],
      cartNum: 0,
    };
    this.routeProductDetail = this.routeProductDetail.bind(this);
    this.speedAddToCart = this.speedAddToCart.bind(this);
  }


  headerRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      list1: productList[0],
      list2: productList[1],
      list3: productList[2],
      list4: productList[3],
    });
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      let cartNum = JSON.parse(localStorage.getItem("cartNum"));
      this.setState({ cartArr, cartNum });
    }
  }

  speedAddToCart(product) {
    let { cartArr, cartNum } = this.state;
    product.num = 1;
    cartNum += product.num;
    if (cartArr.length === 0) {
      cartArr.push(product);
    } else {
      let sameFlag = false; // 初始值为 false
      cartArr = cartArr.map((item) => {
        if (item.id === product.id) {
          sameFlag = true;
          return { ...item, num: item.num + product.num };
        }
        return item;
      });
      if (!sameFlag) {
        cartArr.push(product);
      }
    }
    console.log(cartArr);
    this.setState({ cartArr, cartNum }, () => {
      // 确保 HeaderBar 更新状态
      if (this.headerRef.current) {
        this.headerRef.current.setState({
          cartArr: this.state.cartArr,
          cartNum: this.state.cartNum,
        });
      }
    });
    localStorage.setItem("cartArr", JSON.stringify(cartArr));
    localStorage.setItem("cartNum", JSON.stringify(cartNum));
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    const { list1, list2, list3, list4 } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef}/>
        <div className="banner">
          <p>British Wildflower, Herb, & Vegetable Seeds.</p>
          {/* <div className="btn">EXPLORE ALL OUR SEEDS</div> */}
        </div>
        <div className="sec1-row">
          <div className="title">Wildflower Seed Mixes</div>
          <div className="row">
            <Swiper
              //   modules={[Navigation]}
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //   navigation
            >
              {list1.map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="col" key={index}>
                      <div onClick={() => this.routeProductDetail(item.id)}>
                        <div className="img-box">
                          <img src={item.img[0]} alt="" />
                        </div>
                        <div className="txt">{item.txt}</div>
                        <div className="price">£{item.price}</div>
                      </div>
                      <div
                        className="btn"
                        onClick={() => this.speedAddToCart(item)}
                      >
                        ADD TO CART
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="sec2">
          <div className="sec2-row">
            <div className="li">
              <div className="icon">
                <Package size={28} />
              </div>
              <p>
                <b>Low-Cost Delivery! </b> Just £1 for standard postage, or FREE
                on orders of £5 or more.
              </p>
            </div>
            <div className="li">
              <div className="icon">
                <Percent size={30} />
              </div>
              <p>
                <b>Buy 5 get 20% OFF. </b> Enjoy 20% Off; Order 5 Seed Packets
                or more. Discounted at checkout.
              </p>
            </div>
            <div className="li">
              <div className="icon">
                <Medal size={30} />
              </div>
              <p>
                <b>Simple Sowing. </b> All our seed packets come with complete,
                easy-to-follow instructions.
              </p>
            </div>
            <div className="li">
              <div className="icon">
                <Return size={30} />
              </div>
              <p>
                <b>Returns & Refunds. </b>All our items come with free returns
                and a 90-day money back guarantee.
              </p>
            </div>
          </div>
        </div>
        <div className="sec1-row">
          <div className="title">Individual Wildflower Seeds</div>
          <div className="row">
            <Swiper
              //   modules={[Navigation]}
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //   navigation
            >
              {list2.map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="col" key={index}>
                      <div onClick={() => this.routeProductDetail(item.id)}>
                        <div className="img-box">
                          <img src={item.img[0]} alt="" />
                        </div>
                        <div className="txt">{item.txt}</div>
                        <div className="price">£{item.price}</div>
                      </div>
                      <div
                        className="btn"
                        onClick={() => this.speedAddToCart(item)}
                      >
                        ADD TO CART
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="banner2">
          <div className="box">
            <h1>Easy-to-grow food</h1>
            <p>
              Our vegetable and herb seeds are selected for resilience,
              hardiness, and adaptability, making gardening easier and more
              enjoyable for everyone, especially beginners. Our seeds allow you
              to enjoy fresh produce quickly, even in limited space. Start your
              gardening journey with us and experience the joy of growing your
              own food.
            </p>
            <div className="btn-row"></div>
          </div>
        </div>
        <div className="sec1-row">
          <div className="title">Vegetable Seeds</div>
          <div className="row">
            <Swiper
              //   modules={[Navigation]}
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //   navigation
            >
              {list3.map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="col" key={index}>
                      <div onClick={() => this.routeProductDetail(item.id)}>
                        <div className="img-box">
                          <img src={item.img[0]} alt="" />
                        </div>
                        <div className="txt">{item.txt}</div>
                        <div className="price">£{item.price}</div>
                      </div>
                      <div
                        className="btn"
                        onClick={() => this.speedAddToCart(item)}
                      >
                        ADD TO CART
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="sec1-row">
          <div className="title">Herb Seeds</div>
          <div className="row">
            <Swiper
              //   modules={[Navigation]}
              spaceBetween={50}
              slidesPerView={4}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              //   navigation
            >
              {list4.map((item, index) => {
                return (
                  <SwiperSlide>
                    <div className="col" key={index}>
                      <div onClick={() => this.routeProductDetail(item.id)}>
                        <div className="img-box">
                          <img src={item.img[0]} alt="" />
                        </div>
                        <div className="txt">{item.txt}</div>
                        <div className="price">£{item.price}</div>
                      </div>
                      <div
                        className="btn"
                        onClick={() => this.speedAddToCart(item)}
                      >
                        ADD TO CART
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="banner3"></div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
