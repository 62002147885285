import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/contactUs.scss";

class ContactUs extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <h2>Need to get in touch?</h2>
          <p>
            Simply fill in your details and write your message below. We'll get
            back to you as soon as possible.
          </p>
          <div className="row">
            <p>NAME (required)*</p>
            <input type="text" placeholder="Enter please your name" />
          </div>
          <div className="row">
            <p>EMAIL (required)*</p>
            <input type="text" placeholder="Enter please your email address" />
          </div>
          <div className="row">
            <p>PHONE NUMBER</p>
            <input type="text" placeholder="Enter please your phone number" />
          </div>
          <div className="row">
            <p>YOUR MESSAGE (required)*</p>
            <textarea placeholder="Enter please your message"></textarea>
          </div>
          <div className="row">
            <div className="btn">SUBMIT</div>
          </div>
          <p>
            {/* TODO: 公司经营类型 */}
            <b>Business Type: </b>Sole proprietorship
          </p>
          <p>
            {/* TODO: 业务交易名称 */}
            <b>Business Trading Name: </b>Coredataone
          </p>
          <p>
            {/* TODO: 电话 */}
            <b>Telephone: </b> 020 7706 7032
          </p>
          <p>
            {/* TODO: 邮箱 */}
            <b>Email: </b> info@coredataone.com
          </p>
          <p>
            {/* TODO: 邮寄地址 */}
            <b>Postal Address: </b>3A Back Lord St Halifax HX1 5AG United
            Kingdom.
          </p>
          <p>
            <b>SHIPPING POILICY AND RETURNS</b>
          </p>
          <p>
            Spend £5 or more to get FREE delivery. Otherwise it's just £1 for
            standard postage. We don't offer international shipping.{" "}
          </p>
          <p>
            Our standard delivery is via Royal Mail 2nd Class. We also offer
            great value express delivery options on the checkout page, including
            1st class.{" "}
          </p>
          <p>Free returns within 90 days with a money back guarantee.</p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContactUs;
