import React from "react";

const Package = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#ffffff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="7159"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M930.71 1018.8H93.29a90.1 90.1 0 0 1-90-90V309.32a89.91 89.91 0 0 1 4.56-28.27L78.68 66.93A89.85 89.85 0 0 1 164.12 5.2h695.76a89.85 89.85 0 0 1 85.44 61.73l70.83 214.12a89.91 89.91 0 0 1 4.56 28.27V928.8a90.1 90.1 0 0 1-90 90zM164.12 65.2a30 30 0 0 0-28.48 20.58L64.81 299.89a29.93 29.93 0 0 0-1.52 9.43V928.8a30 30 0 0 0 30 30h837.42a30 30 0 0 0 30-30V309.32a29.93 29.93 0 0 0-1.52-9.43L888.36 85.78a30 30 0 0 0-28.48-20.58zM33.29 269.65H990.7v60H33.29zM482 35.2h60v264.45h-60z m124.52 664.2h233.16v60H606.52z m0 119.5h287.04v60H606.52z m324.19 199.9H93.29a90.1 90.1 0 0 1-90-90V309.32a89.91 89.91 0 0 1 4.56-28.27L78.68 66.93A89.85 89.85 0 0 1 164.12 5.2h695.76a89.85 89.85 0 0 1 85.44 61.73l70.83 214.12a89.91 89.91 0 0 1 4.56 28.27V928.8a90.1 90.1 0 0 1-90 90zM164.12 65.2a30 30 0 0 0-28.48 20.58L64.81 299.89a29.93 29.93 0 0 0-1.52 9.43V928.8a30 30 0 0 0 30 30h837.42a30 30 0 0 0 30-30V309.32a29.93 29.93 0 0 0-1.52-9.43L888.36 85.78a30 30 0 0 0-28.48-20.58zM33.29 269.65H990.7v60H33.29zM482 35.2h60v264.45h-60z m124.52 664.2h233.16v60H606.52z m0 119.5h287.04v60H606.52z"
      fill={color}
      p-id="7160"
    ></path>
  </svg>
);


export default Package;