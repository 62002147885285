// 1
import img1 from "../image/img1.avif";
import img11 from "../image/img1-1.webp";
import img12 from "../image/img1-2.webp";
import img13 from "../image/img1-3.webp";
import img14 from "../image/img1-4.webp";
import img15 from "../image/img1-5.webp";
// 2
import row1 from "../image/row1.jpg";
import row1_1 from "../image/row1-1.webp";
import row1_2 from "../image/row1-2.webp";
import row1_3 from "../image/row1-3.webp";
// 3
import row2 from "../image/row2.jpg";
import row2_1 from "../image/row2-1.webp";
import row2_2 from "../image/row2-2.webp";
import row2_3 from "../image/row2-3.webp";
import row2_4 from "../image/row2-4.webp";
// 4
import row3 from "../image/row3.jpg";
// 5
import L1 from "../image/L1.avif";
import L1_1 from "../image/L1-1.webp";
import L1_2 from "../image/L1-2.webp";
// 6
import L2 from "../image/L2.avif";
import L2_1 from "../image/L2-1.webp";
import L2_2 from "../image/L2-2.webp";

// 7
import L3 from "../image/L3.webp";
import L3_1 from "../image/L3-1.webp";
import L3_2 from "../image/L3-2.webp";
// 8
import L4_1 from "../image/L4-1.webp";
import L4_2 from "../image/L4-2.webp";
import L4_3 from "../image/L4-3.webp";
// 9
import L5 from "../image/L5.avif";
import L5_1 from "../image/L5-1.webp";
// 10
import L6 from "../image/L6.avif";
import L6_1 from "../image/L6-1.webp";
// 11
import L7 from "../image/L7.avif";
import L7_1 from "../image/L7-1.webp";
// 12
import L8 from "../image/L8.avif";
import L8_1 from "../image/L8-1.webp";
// 13
import L9 from "../image/L9.avif";
import L9_1 from "../image/L9-1.webp";
// 14
import L10 from "../image/L10.avif";
import L10_1 from "../image/L10-1.webp";
// 15
import L11 from "../image/L11.avif";
import L11_1 from "../image/L11-1.webp";
// 16
import L12 from "../image/L12.avif";
import L12_1 from "../image/L12-1.webp";
import L12_2 from "../image/L12-2.webp";
import L12_3 from "../image/L12-3.webp";

export const productList = [
  [
    {
      id: 1,
      img: [img1, img11, img12, img13, img14, img15],
      txt: "Wildflower Seed Mix : Pet Friendly & Grazing Safe",
      price: "1.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Create a vibrant and pet-friendly wildflower meadow in your garden with our specially designed mix!</strong><br><br>Attract beautiful bees and butterflies while providing a safe space for your beloved pets, (safe for&nbsp;cats, dogs, horses, rabbits and grazing cattle). Our low-maintenance pet friendly wildflower mix is carefully crafted with a diverse selection of native British species that are fast-growing, attractive, and easy to establish.<br><br>Whether you have borders, allotments, or open fields, this versatile mix is perfect for any outdoor space. As this is a pure mix of wildflowers with no grass seed, you will only need 1g of seeds per square meter to ensure a stunning floral display of: <br></p>
<ul>
<li>Cornflower</li>
<li>Musk Mallow</li>
<li>Sainfoin</li>
<li>White Campion</li>
<li>Crimson Clover</li>
<li>Sheeps Burnet</li>
<li>Oxeye Daisy</li>
<li>Sweet Rocket</li>
<li>There is no grass seed in this mix,&nbsp;only pure wildflower seeds.</li>
</ul>
<p><strong>Growing Instructions:</strong><br></p>
<ol>
<li>Get rid of any weeds &amp; water the soil.</li>
<li>Sprinkle seeds evenly over the soil.</li>
<li>Use 1 gram for every 1 square metre.</li>
<li>Some of the seeds are tiny, so take care. You should aim for the seeds to be about 5cm apart (there are 200 seeds for every 1 gram)</li>
<li>Rake the soil &amp; walk over the soil.</li>
<li>Wait for 2 days, then water again. Dont water immediately when the seeds are down, as the smaller seeds can be washed away. Also avoid heavy rain.&nbsp;</li>
<li>You might also need to protect the seeds from birds.&nbsp;</li>
<li>Seeds will start to grow in 4 weeks.<br>
</li>
</ol>
<p>Experience the joy of gardening while supporting pollinators and creating a safe haven for your furry friends. Get our pet-friendly wildflower mix today and transform your garden into a haven of natural beauty!</p>
<p><em>Can be planted between February and October. Wild flowers will grow between 20cm and 70cm. Suitable for use on a wide range of soil types. Suitable for areas in the sun or shade. Safe for all animals. Approxiimately 200 seeds per gram.&nbsp;There is no grass seed in this mix,&nbsp;only&nbsp;pure wildflower seeds.&nbsp;Mix the seeds before scattering. More detailed planting instructions are available <a href="https://www.pureseeds.co.uk/blogs/planting-wildflower-seeds/how-to-plant-a-wildflower-meadow" title="How to Plant a Wildflower Meadow" data-mce-href="https://www.pureseeds.co.uk/blogs/planting-wildflower-seeds/how-to-plant-a-wildflower-meadow">on our blog.</a></em></p>
<p><strong>Square Metres (1m²) - Weight of Seeds (grams):</strong><br>1sqm = 1g = 200 seeds per gram.&nbsp;<br>2sqm = 2g<br>5sqm = 5g<br>10sqm = 10g<br>50sqm =&nbsp;50g<br>100sqm =&nbsp;100g<br>200sqm =&nbsp;200g = 40000 seeds.<br><br>Rest assured, while the visual perception of seeds may sometimes appear fewer than their actual quantity, we guarantee that our seed packets contain the specified amount, and we encourage you to count the seeds yourself for complete reassurance or photograph the seeds and we will count them for you.<br></p>
<p><span>All seeds come with a 90 day money back guarantee.&nbsp;<br></span><span><strong>We hope these flowers bring you joy!</strong></span></p>

  </div>`,
    },
    {
      id: 2,
      img: [row1, row1_1, row1_2, row1_3],
      txt: "Wildflower Meadow Seed Mix - Cornfield Annuals",
      price: "1.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong></strong>Reimagine&nbsp;your garden with our Wildflower Mix! Enjoy a vibrant spectacle of native British wildflowers, transforming any outdoor space into a colourful haven.&nbsp;</p>
<p>These fast-growing blooms don't just delight the eyes; they support the UK's bee population too, making your garden a bustling wildlife hub.</p>
<p>Our blend is pure and simple. No sand, no grass seeds, just the finest annual wildflowers, chosen for beauty and easy establishment. Perfect for all gardens, borders, allotments, and open fields.</p>
<p>Plant anytime from February to October outdoors or all year round in a greenhouse. Just 2g per square meter creates a lush display, adaptable to your garden's needs.</p>
<p><meta charset="utf-8"><span>Discover the joy of nature with our Cornfield Annual Mix. A blooming marvellous garden transformation awaits you!</span></p>
<p><strong style="font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif; font-size: 0.875rem;" data-mce-style="font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif; font-size: 0.875rem;">Got pets?</strong><span style="font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif; font-size: 0.875rem;" data-mce-style="font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif; font-size: 0.875rem;">&nbsp;Then see our&nbsp;</span><a title="Pet-Friendly Wildflower Seeds" href="https://www.pureseeds.co.uk/collections/products/products/grazing-safe-50-50-wildflower-seed-mix" style="font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif; font-size: 0.875rem;" data-mce-href="https://www.pureseeds.co.uk/collections/products/products/grazing-safe-50-50-wildflower-seed-mix" data-mce-style="font-family: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif; font-size: 0.875rem;">Pet-Friendly Wildflower Seed Mix here.</a></p>
<p><strong>Growing Instructions:</strong><br></p>
<ol>
<li>Get rid of any weeds &amp; water the soil.</li>
<li>Sprinkle seeds evenly over the soil.</li>
<li>Use 2 grams for every 1 square metre.</li>
<li>Some of the seeds are tiny, so take care. You should aim for the seeds to be about 5cm apart (there are 100 seeds for every 1 gram)</li>
<li>Rake the soil &amp; walk over the soil.</li>
<li>Wait for 2 days, then water again. Dont water immediately when the seeds are down, as the smaller seeds can be washed away. Also avoid heavy rain.&nbsp;</li>
<li>You might also need to protect the seeds from birds.&nbsp;</li>
<li>Seeds will start to grow in 4 weeks.<br>
</li>
</ol>
<ol></ol>
<p><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Flowers will grow between 20cm to 70cm. Suitable for use on a wide range of soil types. Suitable for areas in the sun or shade. More detailed planting instructions can be found </span><a href="https://www.pureseeds.co.uk/blogs/planting-wildflower-seeds/how-to-plant-a-wildflower-meadow"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">on our blog.<br></span></a></p>
<p><b>Seed Contents:</b></p>
<ol>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;"><meta charset="utf-8">Corn Cockle (45%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;"><meta charset="utf-8">Cornflower (20%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Corn Marigold (15%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Crimson Clover (8%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;"><meta charset="utf-8">Poppy, Common (5%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Sainfoin (4%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;">
<span style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Meadow Buttercup (3%)&nbsp;<br></span></span>There is no grass seed in this mix,&nbsp;only<span>&nbsp;</span>pure wildflower seeds.</li>
</ol>
<p><strong>Square Metres (1m²) - Weight of Seeds (grams):</strong><br>1sqm = 2g<br>2sqm = 4g<br>5sqm = 10g<br>10sqm = 20g<br>50sqm = 100g<br>100sqm = 200g<br>200sqm = 400g<br></p>
<p>Remember to only use 2g of wildflower seed mix per square metre of soil. Sowing seeds too&nbsp;close will lead to overcrowding of plants, preventing roots from establishing and limiting growth.</p>
<p><span>All seeds come with a 90 day money back guarantee. </span></p>
<p><span><strong>We hope these flowers bring you joy!</strong></span></p>

  </div>`,
    },
    {
      id: 3,
      img: [row2, row2_1, row2_2, row2_3, row2_4],
      txt: "King's Coronation Wildflower Seed Mix - Red, White, & Blue Flowers",
      price: "1.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong><meta charset="utf-8"> <span data-mce-fragment="1">Celebrate the Coronation of King Charles with Our King's Coronation Wildflower Seed Mix!</span></strong></p>
<p>Join us in celebrating the Coronation of King Charles with our King's Coronation Wildflower Seed Mix! This exclusive blend of native British wildflowers is carefully curated to create a colourful red, white, and blue floral decoration display in your garden.</p>
<p>Sow the seeds and you will have a display of stunning red poppies, blue cornflowers, and white oxeye daisies and chamomile, representing not only the flag colours of the Union Jack but also the nations of the UK, on this special occasion.<br data-mce-fragment="1"><br data-mce-fragment="1"><strong>Available in packs of:</strong></p>
<ul>
<li>1 Square Metre</li>
</ul>
<p><strong>Planting Instructions:</strong><br data-mce-fragment="1"></p>
<ol>
<li>Clear the area of weeds and other plants<br>
</li>
<li>Water the ground first</li>
<li>Scatter the seeds evenly over cleared soil</li>
<li>Rake over the surface or firm down</li>
<li>Water again after 2 days (avoid heavy rain as poppy seeds can wash away)&nbsp;</li>
<li>Seeds should germinate within 4 weeks.</li>
</ol>
<p><strong>Sow outdoors:</strong> February to&nbsp;June</p>
<p><meta charset="utf-8"><strong data-mce-fragment="1">Contains:</strong><span data-mce-fragment="1">&nbsp;Cornflower, Field Poppy, Oxeye Daisy, and Corn Chamomile seeds. 30% of the mix contains&nbsp;</span><meta charset="utf-8"><span data-mce-fragment="1">Meadow Grasses that help to support insects in the lower part of the meadow and provide scaffolding to support the stems of the wildflowers.&nbsp;</span><span data-mce-fragment="1">This meadow mix will produce flowers in the colours of the Union Jack flag.</span></p>
<p>This mix is suitable for all soil types, in full sun to partial shade. Contains a mix of annual and perennial flowers.&nbsp;<br></p>
<p><span data-mce-fragment="1">All seeds come with a 90 day money back guarantee. All come in collectible packets with the Buckinham Palace official coronation crest. Great to give out to friends.&nbsp;</span></p>
<p><strong data-mce-fragment="1">We hope these flowers bring you joy!</strong></p>

  </div>`,
    },
    {
      id: 4,
      img: [row3],
      txt: "Wildflower Seed Meadow Mix (10sqm) no grass",
      price: "9.00",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Bring life to your garden with our wildflower meadow mix!</strong><br></p>
<p><strong>Need more seeds?&nbsp;</strong><a title="Wildflower Meadow Seeds Mix" href="https://www.pureseeds.co.uk/products/wildflower_meadow_seeds_cornfield">Visit our other listing</a> to choose from a range of quantities.</p>
<p>Transform your garden into a vibrant haven with our Wildflower Mix! Radiate colour with our native British wildflowers. These quick-growing, fast-establishing annual blooms not only dazzle the senses but also support the UK's bee population. Watch your garden evolve into a lively hub for nature.<br data-mce-fragment="1"><br data-mce-fragment="1">Our blend is pure and straightforward. No sand, no grass seeds, just the finest annual wildflowers, handpicked for their beauty. Perfect for all spaces - be it gardens, borders, allotments, or open fields.<br data-mce-fragment="1"><br data-mce-fragment="1">Sow the seeds at your convenience, outdoors from February to October or all year round in a greenhouse.&nbsp;These seeds unfold over 10 square metres to create a lush floral display, adaptable to your garden's size and needs.<br data-mce-fragment="1"><br data-mce-fragment="1">Rediscover the joy of nature with our Cornfield Annual Mix. Get ready for a blooming marvellous transformation of your garden!<br></p>
<p><strong>Growing Instructions:</strong><br></p>
<ol>
<li>Get rid of any weeds &amp; water the soil.</li>
<li>Sprinkle seeds evenly over the soil.</li>
<li>Use 2 grams for every 1 square metre.</li>
<li>Some of the seeds are tiny, so take care. You should aim for the seeds to be about 5cm apart (there are 100 seeds for every 1 gram)</li>
<li>Rake the soil &amp; walk over the soil.</li>
<li>Wait for 2 days, then water again. Dont water immediately when the seeds are down, as the smaller seeds can be washed away. Also avoid heavy rain.&nbsp;</li>
<li>You might also need to protect the seeds from birds.&nbsp;</li>
<li>Seeds will start to grow in 4 weeks.<br>
</li>
</ol>
<ol></ol>
<p><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Flowers will grow between 20cm to 70cm. Suitable for use on a wide range of soil types. Suitable for areas in the sun or shade. More detailed planting instructions can be found<span>&nbsp;</span></span><a href="https://www.pureseeds.co.uk/blogs/planting-wildflower-seeds/how-to-plant-a-wildflower-meadow"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">on our blog.<br></span></a></p>
<p><b>Seed Contents:</b></p>
<ol>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;"><meta charset="utf-8">Corn Cockle (45%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;"><meta charset="utf-8">Cornflower (20%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Corn Marigold (15%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Crimson Clover (8%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;"><meta charset="utf-8">Poppy, Common (5%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;"><span style="font-weight: 400;" data-mce-style="font-weight: 400;">Sainfoin (4%)</span></li>
<li style="font-weight: 400;" data-mce-style="font-weight: 400;">
<span style="font-weight: 400;" data-mce-style="font-weight: 400;">Meadow Buttercup (3%)<br></span>There is no grass seed in this mix,&nbsp;only<span>&nbsp;</span>pure wildflower seeds.</li>
</ol>
<p><strong>Square Metres (1m²) - Weight of Seeds (grams):</strong><br>10sqm = 20g<br>If you require more seeds for a bigger area, then please <a href="https://www.pureseeds.co.uk/products/wildflower_meadow_seeds_cornfield" title="Wildflower Meadow Seeds Mix">view our other listing here</a>:&nbsp;</p>
<p>Remember to only use 2g of wildflower seed mix per square metre of soil. Sowing seeds too&nbsp;close will lead to overcrowding of plants, preventing roots from establishing and limiting growth.</p>
<p><span>All seeds come with a 90 day money back guarantee.</span></p>
<p><span><strong>We hope these flowers bring you joy!</strong></span></p>

  </div>`,
    },
  ],
  [
    {
      id: 5,
      img: [L1, L1_1, L1_2],
      txt: "Sweet Rocket Seeds (Hesperis matronalis)",
      price: "2.25",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Great &amp; Salad Burnet&nbsp;<em>(Sanguisorba)</em> Seeds</strong></p>
<p>Found growing naturally in floodplains and damp meadows, great burnet is a beautiful plant that produces a display of deep red flower clusters atop tall green stems. Sadly, this lovely flower is in decline due to the loss of floodplain habitats. A member of the rose family <em>rosaceae</em>, this is a perennial that will spread happily and keep flowering for many years to come. Reaching heights of 45-90cm tall,&nbsp;great burnet is a wonderful choice for growing in borders amongst other plants or meadow grasses, as the flower stalks will sit nicely among other plants.</p>
<p>This is an edible plant -&nbsp;you can use young leaves as a herb with a spicy flavour, or add the flower buds to a salad.&nbsp;A plant that&nbsp;keeps on giving, great burnet will self-seed freely and produces rhizomes which can be dug up and divided to create new plants. With a late flowering season that can run&nbsp;until November, <em>sanguisorba officinalis</em> is loved by pollinators and will add a lovely pop of colour to your autumn garden.</p>
<p>It is very hard to tell the seeds of Great Burnet and Salad Burnet apart, so this is sold as a mixture of the two. They produce very similar plants.</p>
<p><strong>﻿Packet contains:</strong>﻿ 50 seeds<br></p>
<ul></ul>
<p><strong>Planting Instructions:</strong></p>
<ol>
<li>This seed is dormant and requires a period of cold, so should be&nbsp;sown in autumn, or in spring just before frost</li>
<li>Sow seed sparingly in flowering position or in trays in a cold frame</li>
<li>Water in and keep moist</li>
<li>Seed can take around 2 months to germinate</li>
<li>Keep plants well watered as they enjoy damp conditions</li>
</ol>
<p><strong>Sow outdoors:</strong>&nbsp;Early Spring (before frost) or Autumn</p>
<p>This plant is safe for pets and grazing animals. This is a damp-loving plants that prefers full sun to partial shade and moist, well-drained soil. This is a rhizomatous hardy perennial.&nbsp;</p>
<p><span data-mce-fragment="1">All seeds come with a 90 day money back guarantee. </span><strong data-mce-fragment="1">We hope these flowers bring you joy!</strong></p>

  </div>`,
    },
    {
      id: 6,
      img: [L2, L2_1, L2_2],
      txt: "Corncockle Seeds (Agrostemma githago)",
      price: "1.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Corncockle&nbsp;<em>(Agrostemma githago)</em> Seeds</strong></p>
<p>A beautiful herbaceous annual with five-petalled pink flowers, corncockle is a wonderful addition to any garden. Sadly, this wildflower has seen a dramatic decline in numbers, and is now almost extinct in the wild. Displaying dainty yet eye-catchingly bright magenta flowers from May right through until September, this is an attractive plant to bees and a lovely addition to any flower border or meadow. A tall, upright&nbsp;cornfield annual that can reach up to 1m, corn cockle is great for adding height to any floral display. Each delicate, rounded flower is surrounded with narrow, pointed leaves atop long, elegant stems. Corncockle is a truly wonderful sight in any garden.</p>
<p><strong>﻿Packet contains:</strong>﻿ 50 seeds<br></p>
<ul></ul>
<p><strong>Planting Instructions:</strong></p>
<ol>
<li>Sow sparingly onto cleared soil in flowering position<br>
</li>
<li>Water seeds in and keep well-watered</li>
<li>Seeds usually germinate in 7 to 14 days</li>
<li>Autumn-sown seeds will flower the following year</li>
</ol>
<p><strong>Sow outdoors:</strong> Spring (for best results) or Autumn</p>
<p><strong>Planting tip:&nbsp;</strong>Plant close together to get smaller corncockles (around 40cm tall) or further apart to acheive&nbsp;taller plants.</p>
<p>Not for safe for animals. Do <strong>not</strong> grow this plant near sheep or chickens. Do <strong>not</strong> eat any seeds or plant matter. Grows well in most soils but prefers sunny, well-drained conditions. This is an annual flower.</p>
<p><span>All seeds come with a 90 day money back guarantee. </span><strong>We hope these flowers bring you joy!</strong></p>

  </div>`,
    },
    {
      id: 7,
      img: [L3, L3_1, L3_2],
      txt: "Cornflower Seeds (Centaurea cyanus)",
      price: "1.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Blue Cornflower <em>(Centaurea cyanus)</em> Seeds</strong></p>
<p>One of nature's rare blue flowers, these iconic blooms sport many individual petals, each with a unique star shape. An increasingly rare sight in the wild, cornflowers are now classed as endangered in the UK. Sow these seeds to experience the joy of watching each<span style="font-weight: 400;" data-mce-fragment="1" data-mce-style="font-weight: 400;">&nbsp;bud open into a wonderful ruff of fringed&nbsp;petals in a stunning shade of sky blue.</span></p>
<p><strong>﻿Packet contains:</strong>﻿ 100 seeds<br></p>
<ul></ul>
<p><strong>Planting Instructions:</strong></p>
<ol>
<li>Sow seeds thinly in&nbsp;a sunny position where you want them to flower</li>
<li>Cover lightly with soil, water well and keep moist</li>
<li>Seeds should germinate in 10 to 14 days</li>
<li>If sown in Autumn, flowers will appear the following year</li>
</ol>
<p><strong>Sow outdoors:&nbsp;</strong>Autumn or Spring (seeds sown in autumn will flower earlier the following year)</p>
<p><span>This flower is safe for pets and grazing animals. Prefers sunny, well-drained conditions. This is a hardy annual.</span></p>
<p><span>All seeds come with a 90 day money back guarantee. <strong>We hope these flowers bring you joy!</strong></span></p>

  </div>`,
    },
    {
      id: 8,
      img: [L4_1, L4_2, L4_3],
      txt: "Field Poppy Seeds (Papaver rhoeas)",
      price: "1.25",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Field Poppy&nbsp;<em>(Papaver rhoeas)</em> Seeds</strong></p>
<p>A striking bright red flower with delicate and easily recognisable petals, the common poppy stands out in any setting. Immortalised in poetry, poppies have long been a symbol of remembrance. Due to intense agricultural practices, this native British flower is now sadly in decline. Help preserve this species by bringing these vibrant flowers into your garden. From vivid scarlet flower through to beautiful, distinctive seed head, it's sure to brighten and add interest to any space. You'll also be rewarded with the sight of many bees frequenting your poppy patch.</p>
<p><strong>﻿Packet contains:</strong>﻿ 200 seeds<br></p>
<ul></ul>
<p><strong>Planting Instructions:</strong></p>
<ol>
<li>Clear and rake the soil, then sow seeds thinly over the surface</li>
<li>Water the seeds and keep moist while the seeds germinate</li>
<li>Seeds should germinate in 7 to 30 days, or in spring&nbsp;if sown in autumn</li>
<li>Continue watering plants during dry spells</li>
</ol>
<p><strong>Sow outside:&nbsp;</strong>August to October (for best results) or March to May</p>
<p>Poppies grow best in a sunny spot with well drained soil. This is an annual species.</p>
<p><span>All seeds come with a 90 day money back guarantee. </span><strong>We hope these flowers bring you joy!</strong></p>

  </div>`,
    },
  ],
  [
    {
      id: 9,
      img: [L5, L5_1],
      txt: "Vegetable & Herb Seed Multipack - 10 Pack",
      price: "7.00",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Vegetable and Herb Seed Multipack<br>Contains 10 Easy to grow vegetable and herb seeds.</strong></p>
<p>This is the ideal garden starter pack for anyone wanting to grow herbs and vegetables at home. This pack contains 10 allotment favourites, with each variety specially selected to be easy to grow and give abundant harvests. With simple sowing instructions and a total of 2500 seeds, this pack is ideal for any gardener and will provide ample food for all the family.&nbsp;</p>
<p><strong>Seeds included in&nbsp;the&nbsp;pack:</strong></p>
<ol>
<li>
<strong>Basil</strong>&nbsp;</li>
<li><strong>Coriander</strong></li>
<li><strong>Chilli</strong></li>
<li><strong>Leek</strong></li>
<li><strong>Lettuce</strong></li>
<li><strong>Parsley</strong></li>
<li><strong>Spring Onion</strong></li>
<li><strong>Radish</strong></li>
<li><strong>Tarragon</strong></li>
<li><strong>Tomato</strong></li>
</ol>
<p>All seeds are&nbsp;packed individually inside the main packet.</p>
<p><strong>Sowing Instructions:</strong></p>
<ul>
<li>
<strong>Basil, Coriander, Tarragon, Parsley:&nbsp;<br></strong>Sow indoors all year round. Sow outdoors from March, or May for Basil. Sow seed thinly in compost then cover lightly and water in.&nbsp;</li>
<li>
<strong>Chilli, Tomato:&nbsp;<br></strong>Sow indoors January - April. Sow in pots or trays of compost. Cover lightly with compost and keep moist. Cover with a clear lid and keep somewhere light and warm (around 20°C).&nbsp;<br><em><strong>Wash hands after touching Chilli seeds. Not to be planted by children.</strong></em>
</li>
<li>
<strong>Lettuce, Leek, Radish, Spring Onion:&nbsp;<br></strong>Sow outdoors March - July. Sow thinly 1cm (1/2in) deep in rows 15cm (6in) apart or 30cm (12in) apart for lettuce. Cover with soil and keep moist.&nbsp;<strong><br></strong>
</li>
</ul>
<p>All these plants are grown as annuals and will be ready to harvest the same year as planting.</p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our vegetable and herb seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
    {
      id: 10,
      img: [L6, L6_1],
      txt: "Carrot Nantes Seeds",
      price: "1.70",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Nantes Carrot Seeds&nbsp;</strong>﻿(400 Seeds)</p>
<p>A lovely early carrot, Nantes produces blunt-ended roots&nbsp;with a small core. This variety is ideal for growing in pots or planters, making it perfect for gardeners with limited space. It can be harvested as a baby carrot or allowed to grow fully, either way it produces sweet roots with good colour.&nbsp;<br></p>
<p><strong>Number of seeds per pack:</strong>&nbsp;400<br></p>
<p><strong>Growing&nbsp;Instructions:</strong></p>
<ol>
<li>Sow thinly outdoors in pre-watered rows 1cm (1/2in) deep and 25cm (10in) apart. Cover lightly with compost, firm down and keep moist. Early Feb-Mar sowings will need covering with fleece to provide warmth.&nbsp;</li>
<li>Seeds should germinate in 14-21 days. Keep&nbsp;free of weeds and water&nbsp;consistently to prevent roots from splitting.</li>
<li>Harvest young carrots alternately and allow remaining roots to develop to full size.&nbsp;</li>
<li>Plants may need to be covered with netting or fleece to protect from carrot fly.&nbsp;</li>
</ol>
<p><strong>Sow outdoors:&nbsp;</strong>February&nbsp;- July<br></p>
<p>This is a&nbsp;biennial - the roots will be ready to harvest in the year of planting, while any plants left in the ground will produce seed&nbsp;in the second year.</p>
<p><strong>We've done the work for you.</strong>&nbsp;All our vegetable seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</p>

  </div>`,
    },
    {
      id: 11,
      img: [L7, L7_1],
      txt: "Chilli Pepper Jalapeno Seeds",
      price: "0.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Jalapeno Chilli Pepper Seeds</strong>﻿ (10 Seeds)</p>
<p>A well-known&nbsp;and versatile variety of chilli, jalapenos are a great all-rounder. Produces small to medium size chillies which can be harvested green or left to ripen and go red. Suitable for growing in a greenhouse or polytunnel, or outdoors.</p>
<p><strong>Number of seeds per pack:</strong> 10<br></p>
<p><strong>Growing&nbsp;Instructions:</strong></p>
<ol>
<li>Sow thinly indoors in pots or trays of seed compost. Cover lightly with compost and keep moist but don't overwater.</li>
<li>Cover with a clear lid and place somewhere warm (such as a heated propagator) and keep at around 20°C. Seeds should germinate in 7-21 days.&nbsp;</li>
<li>Once seedlings appear remove the cover and grow on in larger pots.&nbsp;</li>
<li>Can be transplanted outside&nbsp;into a sunny position from May or grown in a greenhouse for a larger harvest.&nbsp;</li>
</ol>
<p><strong>Sow indoors:</strong> January - April</p>
<p><em><strong>﻿Warning:</strong> Avoid eye contact and wash hands after touching these seeds, can irritate eyes and skin.</em></p>
<p>﻿This is a perennial plant if overwintered indoors, but is usually grown as an annual. It will give you harvests within the first year of growing.</p>
<p><strong>Spicy Heat Score:</strong>&nbsp;Jalapenos can be between 2500 and 8000 scoville units on the Scoville Heat Scale. They are a medium heat chilli pepper.&nbsp;You can easily customise the heat level of a recipe by adding more or less chilli, or removing the seeds for a milder taste.</p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our vegetable seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
    {
      id: 12,
      img: [L8, L8_1],
      txt: "Cucumber Marketmore Seeds",
      price: "0.80",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Marketmore Cucumber&nbsp;Seeds</strong>﻿ (10 seeds)</p>
<p>A reliable ridge variety that produces large crops, Marketmore is a favourite for a reason. Suitable for indoor or outdoor growing, it’s a great fit for any space and produces fruit that can be eaten small, or left to develop into full size cucumbers.</p>
<p><strong>Number of seeds per pack:</strong>&nbsp;10</p>
<p><strong>Indoor Growing&nbsp;Instructions:</strong></p>
<ol>
<li>Cucumber seeds should be sown on their sides to prevent rotting. Sow indoors 1cm (1/2in) deep in pots or trays of seed compost and cover with a clear lid then keep at a<span data-mce-fragment="1">round 20 °C.</span>
</li>
<li>Seeds will usually germinate in 7-21 days. Keep compost moist but not wet.</li>
<li>Once seedlings appear remove the cover and grow on in warm conditions.</li>
<li>Grow on in a greenhouse or transplant outside once all risk of frost has passed. Final spacing of plants should be 60cm (24in) apart.&nbsp;</li>
</ol>
<p><strong>Outdoor Growing Instructions:</strong></p>
<ol>
<li>﻿Sow seeds outdoors from May on their sides 1cm (1/2in) deep in rows 20cm (8in) apart. Cover with compost and water in.</li>
<li>As seedlings develop thin or transplant to 60cm (24in) spacing.&nbsp;</li>
<li>Plants will need supports such as stakes or frames as they grow.</li>
</ol>
<p><strong>Sow indoors:</strong>&nbsp;February - April<br><strong>Sow outdoors:&nbsp;</strong>May - June<br></p>
<p>This is an annual plant and will be ready to harvest within a year of planting. You do&nbsp;<strong>not</strong> need to remove the male flowers from this variety, it needs to be pollinated to produce fruit and this will not affect the taste of the cucumbers.&nbsp;</p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our vegetable seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
  ],
  [
    {
      id: 13,
      img: [L9, L8_1],
      txt: "Basil Sweet Herb Seeds Plant",
      price: "1.25",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Sweet Basil&nbsp;Seeds&nbsp;</strong>﻿(250 Seeds)</p>
<p><span style="font-weight: 400;">A distinct and flavoursome herb used in all kinds of Italian cooking, basil is a wonderful addition to any herb garden or kitchen windowsill. A quick growing plant, it’s ideal for picking fresh leaves to top off your pizza or pasta.&nbsp;If you grow enough you can&nbsp;get ambitious making your own pesto!</span></p>
<p><strong>Number of seeds per pack:</strong>&nbsp;250<br></p>
<p><strong>Growing&nbsp;Instructions:</strong></p>
<ol>
<li>Indoors sow thinly in trays or pots of compost, then cover lightly.</li>
<li>Water, firm down and keep warm and moist. Seeds should germinate in 7-14 days.</li>
<li>Outdoors sow thinly in growing position. Thin seedlings to 15cm (6in) apart.</li>
<li>To harvest, pick leaves as required.</li>
</ol>
<p><strong>Sow indoors:</strong>&nbsp;All year round<br><strong>Sow outdoors:&nbsp;</strong>May - June<br></p>
<p><em><strong></strong></em>Basil is a tender perennial - it can keep growing for years indoors but outdoors is best grown as an annual, as it won't survive cold weather over winter.&nbsp;It will give you harvests within the first year of growing.</p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our vegetable seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
    {
      id: 14,
      img: [L10, L10_1],
      txt: "Chive Seeds",
      price: "1.75",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Chive Seeds</strong> (250 Seeds)</p>
<p><meta charset="utf-8"><span>Looking for an easy-to-grow plant that's both a wildflower and herb? Chives are perfect! This perennial herb will keep coming back year after year and requires very little care once established. Both the leaves and beautiful purple flowers are edible - simply cut a few away from the plant when needed and it will continue growing, giving you a nice mild onion flavour. Chives are also packed with vitamins and minerals, making them a nutritious addition to your cooking.&nbsp;</span></p>
<p><strong>Number of seeds per pack:</strong>&nbsp;250<br></p>
<p><strong>Growing&nbsp;Instructions:</strong></p>
<ol>
<li>Sow thinly in pots or trays of compost for indoor growing. Cover lightly, water in and keep warm and moist.</li>
<li>Outdoors sow thinly direct in growing position. Cover lightly, firm down gently and keep moist.</li>
<li>Seeds should germinate in 14-28 days. As seedlings grow, thin to 20cm (8in) apart.</li>
<li>Cut leaves and flowers as required once plants have established</li>
</ol>
<p><strong>Sow indoors:</strong>&nbsp;All year round<br><strong>Sow outdoors:&nbsp;</strong>March -&nbsp;May<br></p>
<p>Chives are a perennial&nbsp;herb. They will give you harvests within the first year of growing.&nbsp;The flowers are edible. The plants will die down in winter and regrow in spring.</p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our vegetable seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
    {
      id: 15,
      img: [L11, L11_1],
      txt: "Coriander Herb Seeds",
      price: "1.75",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Coriander&nbsp;Seeds</strong>﻿ (100 Seeds)</p>
<p><span style="font-weight: 400;">A fresh, leafy herb with a distinct look and flavour, coriander is an essential ingredient in lots of curries and Mexican dishes. The frilly-edged leaves make this an attractive plant to grow on your kitchen windowsill, ready for picking for a fresh garnish. It will also grow happily outside in a cool spot.</span></p>
<p><strong>Number of seeds per pack:</strong>&nbsp;100<br></p>
<p><strong>Growing&nbsp;Instructions:</strong></p>
<ol>
<li>Indoors sow thinly in trays or pots of compost, then cover lightly.</li>
<li>Water, firm down and keep warm and moist. Seeds should germinate in&nbsp;14-21 days.</li>
<li>Outdoors sow thinly in growing position. Thin seedlings to 15cm (6in) apart.</li>
<li>To harvest, pick leaves as required.</li>
</ol>
<p><strong>Sow indoors:</strong>&nbsp;All year round<br><strong>Sow outdoors:&nbsp;</strong>March - September<br></p>
<p>Coriander is an annual herb.&nbsp;It will give you harvests within the first year of growing. Excessive heat will cause the plant to bolt, producing flowers and seeds early. The seeds are edible and are often used ground or whole as a spice.</p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our vegetable seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
    {
      id: 16,
      img: [L12, L12_1, L12_2, L12_3],
      txt: "Cress Seeds",
      price: "0.95",
      description: `<div class="tabs__content rte overflow-hidden" data-js-tabs-content="" style="display: block;">
      <p><strong>Cress Seeds&nbsp;</strong>﻿(2g of Seeds)</p>
<p><span style="font-weight: 400;">Perhaps the best-known microgreen, cress is one of the easiest and quickest plants you can grow, making it especially good for growing with children. You don’t need any special equipment, just a small plastic tray (such as a recycled fruit punnet) and some kitchen paper or compost. Cress can be grown on any sunny windowsill, and as long as it is kept moist you can be harvesting in just 2 weeks!</span></p>
<p><strong>Amount&nbsp;of seeds per pack:</strong>&nbsp;2g<br></p>
<p><strong>Growing&nbsp;Instructions:</strong></p>
<ol>
<li><span style="font-weight: 400;">Sow thinly in a tray of moist compost or kitchen paper, do not cover. </span></li>
<li><span style="font-weight: 400;">Place on a sunny windowsill and keep moist.</span></li>
<li><span style="font-weight: 400;">Shoots are ready to harvest when approximately 5cm (2in) tall, around 14 days after sowing depending on temperature.</span></li>
</ol>
<p><strong>Sow indoors:</strong>&nbsp;All year round<br></p>
<p>Cress is an annual and will be ready to harvest within weeks of sowing. To harvest cut shoots away near the base with clean scissors.<br></p>
<p><strong>We've done the work for you.</strong><span>&nbsp;All our&nbsp;herb seeds have been specially selected by experienced allotment holders as the easiest and best varieties to grow. They will provide you with abundant harvests, so you can spend less time researching and more time gardening!</span></p>

  </div>`,
    },
  ],
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    let products = productList[i];
    for (let j = 0; j < products.length; j++) {
      if (products[j].id === id) {
        return products[j];
      }
    }
  }
};
