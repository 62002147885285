import React from "react";

const Percent = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="12274"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 64C264.8 64 64 264.8 64 512s200.8 448 448 448 448-200.8 448-448S759.2 64 512 64z m0 820c-205.6 0-372-166.4-372-372S306.4 140 512 140s372 166.4 372 372-166.4 372-372 372zM282.4 404.8c0-34.4 9.6-61.6 28-81.6 19.2-20.8 44.8-30.4 76.8-30.4 31.2 0 54.4 9.6 72 28 16.8 18.4 25.6 44 25.6 76.8 0 33.6-9.6 60.8-28.8 80.8-19.2 20-44 30.4-76 30.4-29.6 0-53.6-9.6-71.2-28.8-17.6-19.2-26.4-44-26.4-75.2z m58.4-3.2c0 38.4 14.4 57.6 42.4 57.6 28.8 0 43.2-20 43.2-60 0-39.2-13.6-58.4-41.6-58.4-28.8 0-44 20.8-44 60.8z m340-104L410.4 714.4h-67.2l270.4-416.8h67.2zM540 616c0-34.4 9.6-61.6 28.8-82.4 19.2-20 44.8-30.4 76.8-30.4 31.2 0 54.4 8.8 72 27.2 16.8 18.4 25.6 44 25.6 76.8 0 34.4-9.6 61.6-28.8 81.6-19.2 20-44.8 30.4-76 30.4-29.6 0-53.6-9.6-71.2-28.8s-27.2-43.2-27.2-74.4z m59.2-3.2c0 38.4 14.4 58.4 42.4 58.4 28.8 0 43.2-20 43.2-60 0-19.2-4-33.6-11.2-43.2-8-10.4-17.6-15.2-30.4-15.2-29.6-0.8-44 20-44 60z"
      fill={color}
      p-id="12275"
    ></path>
  </svg>
);

export default Percent;
