import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import ShoppingCart from "../../icon/ShoppingCart";
import CloseIcon from "../../icon/CloseIcon";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartArr: props.receiveCartArr || [],
      cartNum: props.receiveNum || 0,
    };
    this.routePage = this.routePage.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      let cartNum = JSON.parse(localStorage.getItem("cartNum"));
      this.setState({ cartArr, cartNum });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.receiveCartArr !== this.props.receiveCartArr ||
      prevProps.receiveNum !== this.props.receiveNum
    ) {
      this.setState({
        cartArr: this.props.receiveCartArr,
        cartNum: this.props.receiveNum,
      });
    }
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "ship":
        history.push("/Ship");
        break;
      case "refund":
        history.push("/Refund");
        break;
      case "service":
        history.push("/Service");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }

  render() {
    let { cartNum } = this.state;
    return (
      <React.Fragment>
        <div className="nav">
          Enjoy a further 20% discount when you buy 5 seed packets or more!
        </div>
        <div className="second-nav">
          <p></p>
          <h4 onClick={() => this.routePage("home")}>Core Data One Seeds</h4>
          <p onClick={() => this.routePage("cart")}>
            <ShoppingCart size={30} color="#000" />
            Cart({cartNum})
          </p>
        </div>
        <div className="head-bar">
          <li onClick={() => this.routePage("home")}>HOME</li>
          <li onClick={() => this.routePage("about")}>ABOUT US</li>
          <li onClick={() => this.routePage("contact")}>CONTACT US</li>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
