import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/productDetail.scss";
import { productList, getProductDetailById } from "../apis/GetProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay, Thumbs } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import "swiper/css/thumbs";
import SwiperCore from "swiper/core";
import Ship from "../icon/Ship";
import Number from "./common/Number";

SwiperCore.use([Navigation, Thumbs]);

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbsSwiper: null,
      cartArr: [],
      cartNum: 0,
    };
    this.routeProductDetail = this.routeProductDetail.bind(this);
    this.AddtoCart = this.AddtoCart.bind(this);
    this.speedAddToCart = this.speedAddToCart.bind(this);
  }

  numberRef = React.createRef();
  headerRef = React.createRef();

  componentDidUpdate(prevProps) {
    // 检查URL参数是否变化
    if (this.props.match.params.id !== prevProps.match.params.id) {
      // 执行当URL参数变化时需要进行的操作
      this.fetchData(this.props.match.params.id);
    }
  }
  setThumbsSwiper = (swiper) => {
    this.setState({ thumbsSwiper: swiper });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (localStorage.getItem("cartArr")) {
      let cartArr = JSON.parse(localStorage.getItem("cartArr"));
      let cartNum = JSON.parse(localStorage.getItem("cartNum"));
      this.setState({ cartArr, cartNum });
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  AddtoCart(product) {
    let { cartArr, cartNum } = this.state;
    if (this.numberRef) {
      product.num = this.numberRef.current.state.num;
      cartNum += product.num; // 更新购物车数量
      if (cartArr.length === 0) {
        cartArr.push(product);
      } else {
        let sameFlag = false; // 初始值为 false
        cartArr = cartArr.map((item) => {
          if (item.id === product.id) {
            sameFlag = true;
            return { ...item, num: item.num + product.num };
          }
          return item;
        });
        if (!sameFlag) {
          cartArr.push(product);
        }
      }
      console.log(cartArr);
      this.setState({ cartArr, cartNum }, () => {
        // 确保 HeaderBar 更新状态
        if (this.headerRef.current) {
          this.headerRef.current.setState({
            cartArr: this.state.cartArr,
            cartNum: this.state.cartNum,
          });
        }
      });
      localStorage.setItem("cartArr", JSON.stringify(cartArr));
      localStorage.setItem("cartNum", JSON.stringify(cartNum));
    }
  }

  speedAddToCart(product) {
    let { cartArr, cartNum } = this.state;
    product.num = 1;
    cartNum += product.num;
    if (cartArr.length === 0) {
      cartArr.push(product);
    } else {
      let sameFlag = false; // 初始值为 false
      cartArr = cartArr.map((item) => {
        if (item.id === product.id) {
          sameFlag = true;
          return { ...item, num: item.num + product.num };
        }
        return item;
      });
      if (!sameFlag) {
        cartArr.push(product);
      }
    }
    console.log(cartArr);
    this.setState({ cartArr, cartNum }, () => {
      // 确保 HeaderBar 更新状态
      if (this.headerRef.current) {
        this.headerRef.current.setState({
          cartArr: this.state.cartArr,
          cartNum: this.state.cartNum,
        });
      }
    });
    localStorage.setItem("cartArr", JSON.stringify(cartArr));
    localStorage.setItem("cartNum", JSON.stringify(cartNum));
  }

  render() {
    let { location } = this.props;
    let id = location.pathname.replace("/ProductDetail/", "");
    let product = getProductDetailById(parseInt(id));
    let randomNum = Math.floor(Math.random() * (0 - 3 + 1)) + 3;

    return (
      <React.Fragment>
        <HeaderBar
          {...this.props}
          ref={this.headerRef}
          receiveCartArr={this.state.cartArr}
          receiveNum={this.state.cartNum}
        />
        <div className="productDetail-page">
          <div className="product-row">
            <div className="left">
              <Swiper
                // modules={[Navigation]}
                spaceBetween={100}
                slidesPerView={1}
                onSlideChange={() => console.log()}
                onSwiper={(swiper) => console.log()}
                // navigation
                thumbs={{ swiper: this.state.thumbsSwiper }}
              >
                {product &&
                  product.img.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <img src={item} alt="" />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
              {/* 缩略图 */}
              <div className="swiper-row">
                <Swiper
                  onSwiper={this.setThumbsSwiper}
                  loop={true}
                  spaceBetween={20}
                  slidesPerView={5}
                  freeMode={true}
                  // watchSlidesVisibility={true}
                  watchSlidesProgress={true}
                  className="myThumbsSwiper"
                >
                  {product &&
                    product.img.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <img src={item} alt="" />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
            <div className="right">
              <div className="title">{product.txt}</div>
              <div className="price">￡{product.price}</div>

              <div className="number-row">
                <Number {...this.props} ref={this.numberRef} />
                <div
                  className="addToCart"
                  onClick={() => this.AddtoCart(product)}
                >
                  ADD TO CART
                </div>
              </div>
              <div className="free-ship">
                {" "}
                <Ship size={20} color="#fff" className="ship-icon" />
                Free Shipping
              </div>
              <div className="info">
                <div className="title">PRODUCT INFORMATION</div>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>
              </div>
            </div>
          </div>
          <div className="related-row">
            <div className="title">You may also like...</div>
            <div className="row">
              <Swiper
                //   modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={4}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                //   navigation
              >
                {productList[randomNum].map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="col">
                        <div onClick={() => this.routeProductDetail(item.id)}>
                          <div className="img-box">
                            <img src={item.img[0]} alt="" />
                          </div>
                          <div className="txt">{item.txt}</div>
                          <div className="price">£{item.price}</div>
                        </div>
                        <div
                          className="btn"
                          onClick={() => this.speedAddToCart(item)}
                        >
                          ADD TO CART
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
