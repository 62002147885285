import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/shippingPolicy.scss";

class ShippingPolicy extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="ship-page">
          <h1>Shipping Policy</h1>
          <p>
            Free UK postage is available on all orders over £6, via Royal Mail
            2nd Class. Postage is only £2 for orders under this value. We do not
            offer international postage. All products come with a 90 day
            guarantee and free returns.{" "}
          </p>
          <div className="table">
            <div className="li">
              <div className="l">
                <h1>Delivery Option</h1>
              </div>
              <div className="l">
                <h1> Estimated Delivery Time</h1>
              </div>
              <div className="l">
                <h1> Cost </h1>
              </div>
            </div>
            <div className="li">
              <div className="l">
                <p>Royal Mail 2nd Class</p>
              </div>
              <div className="l">
                <p>3 - 5 working days</p>
              </div>
              <div className="l">
                <p> £1 or FREE (when you spend £5)</p>
              </div>
            </div>
            <div className="li">
              <div className="l">
                <p>Royal Mail 1st Class</p>
              </div>
              <div className="l">
                <p> 1 - 2 working days</p>
              </div>
              <div className="l">
                <p> £2</p>
              </div>
            </div>
            <div className="li">
              <div className="l">
                <p>Royal Mail Special Delivery</p>
              </div>
              <div className="l">
                <p>1 - 2 working days</p>
              </div>
              <div className="l">
                <p>£7</p>
              </div>
            </div>
            <div className="li">
              <div className="l">
                <p>DPD Parcel (2kg - 28kg)</p>
              </div>
              <div className="l">
                <p>2 - 3 working days</p>
              </div>
              <div className="l">
                <p>£6.00 - £11.00</p>
              </div>
            </div>
            <div className="li">
              <div className="l">
                <p>Parcel Force Express Delivery (2kg - 19kg)</p>
              </div>
              <div className="l">
                <p> 1 - 2 working days</p>
              </div>
              <div className="l">
                <p> £19.00</p>
              </div>
            </div>
            {/* TODO: 公司地址 */}
            <p>Items are sent from our address:</p>
            <p>3A Back Lord St ,</p>
            <p>Halifax,</p>
            <p>HX1 5AG,</p>
            <p>United Kingdom.</p>
            <p></p>
            {/* TODO: 公司电话 */}
            <p>Telephone:020 7706 7032</p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ShippingPolicy;
