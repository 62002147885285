import React from "react";

const ShoppingCart = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#515151",
  ...props
}) => (
  <svg
    viewBox="0 0 1509 1024"
    p-id="7958"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1248.407971 170.983538c-4.943186-8.175269-13.118455-11.470727-23.004827-11.470727H578.796386c-14.766184 0-27.884639 13.118455-27.884639 27.884639s13.118455 27.884639 27.884639 27.884639h607.314766L1074.445847 522.203243H549.200644L458.955811 38.024508c-1.647729-13.118455-14.766184-23.004827-27.884639-23.004827H280.050501c-14.766184 0-27.884639 13.118455-27.884639 27.884639s13.118455 27.884639 27.884639 27.884639h128.015844l114.897388 620.433222c1.647729 13.118455 14.766184 23.004827 27.884639 23.004827h579.366754c14.766184 0 27.884639-13.118455 27.884639-27.884639s-13.118455-27.884639-27.884639-27.884639h-556.425301l-14.766183-80.421834h535.068201c11.470727 0 23.004827-6.590915 26.236911-18.061642l132.959029-362.753806c1.711103-8.175269 1.711103-18.061641-4.879812-26.23691zM591.914841 786.473573c-60.712464 0-111.601931 49.241738-111.601931 111.601931 0 60.712464 49.241738 111.601931 111.601931 111.601931s111.601931-49.241738 111.601931-111.601931c-1.647729-62.360193-50.889467-111.601931-111.601931-111.601931z m0 167.434584c-31.180097 0-55.832653-24.589182-55.832653-55.832653 0-31.180097 24.589182-55.832653 55.832653-55.832652s55.832653 24.589182 55.832653 55.832652c-1.711103 29.532368-26.300285 55.832653-55.832653 55.832653zM1076.093576 786.473573c-60.712464 0-111.601931 49.241738-111.601931 111.601931 0 60.712464 49.241738 111.601931 111.601931 111.601931 60.712464 0 111.601931-49.241738 111.601931-111.601931-1.647729-62.360193-50.889467-111.601931-111.601931-111.601931z m0 167.434584c-31.180097 0-55.832653-24.589182-55.832653-55.832653 0-31.180097 24.589182-55.832653 55.832653-55.832652 31.180097 0 55.832653 24.589182 55.832652 55.832652-1.647729 29.532368-26.300285 55.832653-55.832652 55.832653z"
      fill={color}
      p-id="7959"
    ></path>
  </svg>
);

export default ShoppingCart;
