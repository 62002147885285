import React from "react";
const ShopIcon = ({
  color = { color },
  size = 24,
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="7054"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M337.792 170.666667C245.632 170.666667 170.666667 245.632 170.666667 337.770667v348.437333C170.666667 778.346667 245.632 853.333333 337.792 853.333333h348.416c92.16 0 167.146667-74.986667 167.146667-167.125333V337.770667C853.333333 245.632 778.368 170.666667 686.208 170.666667H337.749333z m348.416 746.666666H337.792C210.346667 917.333333 106.666667 813.653333 106.666667 686.208V337.770667C106.666667 210.325333 210.346667 106.666667 337.792 106.666667h348.416c127.466667 0 231.146667 103.658667 231.146667 231.104v348.437333C917.333333 813.653333 813.653333 917.333333 686.208 917.333333z"
      fill={color}
      p-id="7055"
    ></path>
    <path
      d="M512 501.333333c-99.989333 0-181.333333-81.344-181.333333-181.333333a32 32 0 1 1 64 0c0 64.682667 52.629333 117.333333 117.333333 117.333333s117.333333-52.650667 117.333333-117.333333a32 32 0 1 1 64 0c0 99.989333-81.344 181.333333-181.333333 181.333333"
      fill={color}
      p-id="7056"
    ></path>
  </svg>
);
export default ShopIcon;
